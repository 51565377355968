import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key: 'nopermission',
  path: '/sign/forgot_password',
  title: 'Forgot Password',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'systems.roles.SetPermission',
  path: '/systems/roles/:roleid',
  title: 'Permission',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'systems.logs.callinlog',
  path: '/systems/logs/callinlog',
  title: 'Callin log',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

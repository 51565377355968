import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'customersadd',
  path: '/systems/language',
  title: 'Language',
  component: dynamicWrapper(app,[import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

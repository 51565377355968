import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'nopermission',
  path: '/pages/UploadCsv',
  title: 'Upload csv',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'systems.setting.settings',
  path: '/systems/setting/shopsetting',
  title: 'Settings',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import React, { PureComponent } from "react";
import Icon from "../Icon";
import { Popover, Badge, Avatar } from "antd";
import { router, connect } from "dva";
import cx from "classnames";
import "./style/index.less";
import logoImg from "assets/images/logo.png";
import { FormattedMessage } from 'react-intl';
import config from '@/config';
import SearchBox from "./SearchBox";
const { Link } = router;

/**
 * 其本本局头部区域
 */
@connect(({ global }) => ({ global }))
class NavBar extends PureComponent {
    state = {
        openSearchBox: false,
    };

    static defaultProps = {
        fixed: true,
        theme: "", //'bg-dark',
    };

    toggleFullScreen() {
        if (
            !document.fullscreenElement &&
            !document.mozFullScreenElement &&
            !document.webkitFullscreenElement &&
            !document.msFullscreenElement
        ) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) {
                document.documentElement.msRequestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            }
        }
    }

    onCloseSearchBox = () => {
        this.setState({
            openSearchBox: false,
        });
    };

    onOpenSearchBox = () => {
        this.setState({
            openSearchBox: true,
        });
    };

    render() {

        const { openSearchBox } = this.state;
        const {
            fixed,
            theme,
            onCollapseLeftSide,
            collapsed,
            onExpandTopBar,
            toggleSidebarHeader,
            user,
            isMobile,
            roleType,
        } = this.props;

        const classnames = cx("navbar", {
            "navbar-fixed-top": !!fixed,
            "navbar-sm": isMobile ? true : collapsed,
            ["bg-" + theme]: !!theme,
        });

        return (
            <header className={classnames}>
                <div className="navbar-branding">
                    <Link className="navbar-brand" to="/">
                        <img src={logoImg} alt="logo" />

                    </Link>
                    <span className="toggle_sidemenu_l" onClick={onCollapseLeftSide}>
                        <Icon type="lines" />
                    </span>
                </div>
                {(config.envtype || '').toUpperCase() === "UAT" && <div className="navbar-title">{(config.envtype || '').toUpperCase()}</div>}
                <ul className="nav navbar-nav navbar-right clearfix">

                    <li className="dropdown">
                        <Popover
                            placement="bottomRight"
                            title={<Link to='/systems/notification'> <FormattedMessage id="common.title.notification" defaultMessage="Notification" /></Link>}
                            overlayClassName={cx("navbar-popup", { [theme]: !!theme })}
                            content={""}
                            trigger="click"
                        >
                            <a className="dropdown-toggle">
                                <Icon type="ring" />
                            </a>
                        </Popover>
                    </li>
                    <li className="dropdown">
                        <Popover
                            placement="bottomRight"
                            title={<div><span><FormattedMessage id="common.text.welcome" defaultMessage="Welcome" />  {user && (user.realname || (user.username || '').split('@')[0])}</span>
                                <span style={{ display: 'block', textAlign: 'center' }}>{roleType}</span></div>}
                            overlayClassName={cx("navbar-popup", { [theme]: !!theme })}
                            content={<UserDropDown dispatch={this.props.dispatch} />}
                            trigger="click"
                        >
                            <a className="dropdown-toggle">
                                <Badge >
                                    {/* dot */}
                                    <Avatar src={require("assets/images/Avatarlogo.png")}>
                                        {user && user.username}
                                    </Avatar>
                                </Badge>
                            </a>
                        </Popover>
                    </li>
                </ul>
            </header>
        );
    }
}

const UserDropDown = (props) => (
    <ul className="dropdown-menu list-group dropdown-persist">
        {/* <li className="list-group-item">
            <a className="animated animated-short fadeInUp" href={"##"}>
                <Icon type="gear" /> Settings
      </a>
        </li> */}
        <li className="list-group-item">
            <Link to="/profile/changeprofile" className="animated animated-short fadeInUp">
                <Icon type="gear" /> <FormattedMessage id="common.menu.profile" defaultMessage="Profile" />
            </Link>
        </li>
        <li className="list-group-item">
            <Link to="/timezone" className="animated animated-short fadeInUp">
                <Icon type="gear" />  <FormattedMessage id="common.menu.timezone" defaultMessage="TimeZone" />
            </Link>
        </li>
        {/* <li className="list-group-item">
      <a className="animated animated-short fadeInUp">
        <Icon type="ring" /> 通知
      </a>
    </li> */}
        <li className="list-group-item dropdown-footer">
            <Link to="/sign/login" onClick={() => {
                props.dispatch({
                    type: 'global/loginOut',
                })
            }}>
                <Icon type="poweroff" />  <FormattedMessage id="common.menu.sign_out" defaultMessage="Sign Out" />
            </Link>
        </li>
    </ul>
);

export default NavBar;

import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = (app) => ({
  key: 'jobdetail',
  path: '/job-details',
  title: 'View Job Details',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  props: true
});

export default (app) => createRoute(app, routesConfig);

import React from 'react';
import { connect } from 'dva';
import { Layout } from 'antd';
import { router } from 'dva';
import logoImg from 'assets/images/logo.svg';
import './styles/user.less';
const { Switch, Link } = router;
const { Content, Header, Footer } = Layout;

@connect()
export default class NoneLayout extends React.PureComponent {
  render() {
    const { routerData } = this.props;
    const { childRoutes } = routerData;

    return (
      <Layout className="full-layout fixed mt-20">
        <Header className="navbar">
        </Header>
        <Content>
          <Switch>{childRoutes}</Switch>
        </Content>
        <Footer>
         
        </Footer>
      </Layout>
    );
  }
}
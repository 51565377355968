import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key: 'viewjobs',
  path: '/view-jobs',
  title: 'View Jobs',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';
import Dictionary from './setting/dicctionary';
import setting from './setting/shopsetting';

const routesConfig = (app) => ({
  key: 'systems.setting',
  path: '/systems/setting',
  title: 'Settings',
  indexRoute: '/systems/setting/shopsetting',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  childRoutes: [
    Dictionary(app),
    setting(app),
  ]
});

export default (app) => createRoute(app, routesConfig);

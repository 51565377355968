import React from 'react';
import { connect, router, routerRedux } from 'dva';
import { Layout } from 'antd';
import moment from 'moment';
import NavBar from '@/components/NavBar';
import { LeftSideBar } from '@/components/SideBar';
import TopBar from '@/components/TopBar';
import { pathToRegexp } from 'path-to-regexp';
import { enquireIsMobile } from '@/utils/enquireScreen';
import cookie from 'react-cookies';
import $$ from '@/cmn-utils';
import cx from 'classnames';
import isEqual from 'react-fast-compare';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { AppConstants } from '@/utils/constants';
//import { loginOut } from '@/utils';
import config from '@/config';
import 'assets/styles/transition.less';
import './styles/basic.less';
const _ = require('lodash');
const { Switch } = router;
const { Content, Header } = Layout;
let timeout_default = 30;
let fresh;
/**
 * 基本部局
 * 可设置多种皮肤 theme: [light, grey, primary, info, warning, danger, alert, system, success, dark]
 * 可设置多种布局 [header(固定头), sidebar(固定边栏), breadcrumb(固定面包蟹), tabLayout(标签布局)]
 * @author weiq
 */
@connect(({ global }) => ({ global }))
export default class BasicLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    let user = $$.getStore(AppConstants.USER);
    if (!user || !user.username) {
      user = cookie.load(AppConstants.USER);
    }

    const theme = $$.getStore('theme', {
      leftSide: 'darkgrey', // 左边
      navbar: 'light' // 顶部
    });
    if (!theme.layout) {
      theme.layout = [
        'fixedHeader',
        'fixedSidebar',
        'fixedBreadcrumbs'
      ];
    }
    this.state = {
      collapsedLeftSide: false, // 左边栏开关控制
      leftCollapsedWidth: 60, // 左边栏宽度
      expandTopBar: false, // 头部多功能区开合
      showSidebarHeader: false, // 左边栏头部开关      
      theme, // 皮肤设置
      user,
      currentMenu: {},
      isMobile: false
    };



  }

  componentDidMount() {
    const props = this.props;
    if (props && props.match && props.match.params && props.match.params.lang && ['fr', 'en'].includes(props.match.params.lang)) {
      let lang = "en-Us";
      if (this.props.match.params.lang === 'fr') {
        lang = "fr-FR";
      }

      props.dispatch({
        type: "global/setLanguage",
        payload: {
          language: lang,
        },
      });
    }

    try {

      this.checkLoginState();
    } catch (error) {

    }
    // const { global } = this.props;
    // const { timeout } = global;
    // timeout_default = timeout;
    fresh = setInterval(() => {
      // console.log({ timeout })
      try {
        $$.get("/auth/isofflineuser").then(async (res) => {
          if (res && res.status && res.data) {
            await this.props.dispatch({
              type: 'global/loginOut',

            });
            window.location.href = '/offline.html'
          }
        }).catch(err => {

        });
      } catch (error) {

      }
      let timeout = timeout_default;
      let lastqTime = $$.getStore(AppConstants.LAST_REQUERT_TIME);
      let lastfTime = $$.getStore(AppConstants.REFRESH_TOKEN_TIME);
      try {

        if (!lastqTime) {
          lastqTime = cookie.load(AppConstants.LAST_REQUERT_TIME);
        }
        if (!lastfTime) {
          lastfTime = cookie.load(AppConstants.REFRESH_TOKEN_TIME);
        }
      } catch (error) {
        console.log(error)
      }

      const difftime = moment().diff(
        moment(_.toNumber(lastqTime)),
        'seconds'
      );
      const difffreshTime = moment().diff(
        moment(_.toNumber(lastfTime)),
        'seconds'
      );

      // console.log({ "Seconds-After-Last-Clicked": difftime, "Seconds-For-Refresh-Api": difffreshTime, "Settings-of-Timeout": _.toNumber(timeout) * 60 })
      if (difftime > _.toNumber(timeout) * 60) {
        //console.log('out1')
        this.props.dispatch({
          type: 'global/loginOut',

        });
      }
      if (difffreshTime > _.toNumber(timeout) * 60 - 150) {
        this.props.dispatch({
          type: 'global/refreshToken',
        });
      }
    }, 50000);
    try {
      this.unregisterEnquire = enquireIsMobile(ismobile => {
        const { isMobile, theme } = this.state;
        if (isMobile !== ismobile) {
          // 如查是移动端则不固定侧边栏
          if (ismobile && $$.isArray(theme.layout)) {
            theme.layout = theme.layout.filter(item => item !== 'fixedSidebar');
          }
          this.setState({
            isMobile: ismobile
          });
          this.props.dispatch({
            type: 'global/setIsMobile',
            payload: { isMobile: ismobile }
          });
        }
      });
    } catch (error) {

    }

  }

  // 检查有户是否登录
  checkLoginState() {
    let user = $$.getStore(AppConstants.USER);
    if (!user || !user.username) {

      try {
        user = cookie.load(AppConstants.USER);
      } catch (error) {

      }

    }
    if (!user || !user.token) {
      this.props.dispatch(routerRedux.replace('/sign/login'));
    } else {
      this.props.dispatch({
        type: 'global/getCurrent'
      });
      this.props.dispatch({
        type: 'global/refreshToken',
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { global } = this.props;
    const { timeout } = global;
    if (timeout) {
      timeout_default = timeout;
    }
    if (
      !isEqual(this.props.location.pathname, prevProps.location.pathname) ||
      !isEqual(this.props.global.flatMenu, prevProps.global.flatMenu)
    ) {
      this.setState({
        currentMenu: this.getCurrentMenu(this.props) || {}
      });
    }

  }

  componentWillUnmount() {
    // 清理监听
    this.unregisterEnquire();
    clearInterval(fresh);
  }

  getCurrentMenu(props) {
    const {
      location: { pathname },
      global
    } = props || this.props;
    const menu = this.getMeunMatchKeys(global.flatMenu, pathname)[0];
    return menu;
  }

  getMeunMatchKeys = (flatMenu, path) => {
    return flatMenu.filter(item => {
      return pathToRegexp(item.path).test(path);
    });
  };

  /**
   * 顶部左侧菜单图标收缩控制
   */
  onCollapseLeftSide = _ => {
    const collapsedLeftSide =
      this.state.leftCollapsedWidth === 0
        ? true
        : !this.state.collapsedLeftSide;


    this.setState({
      collapsedLeftSide,
      leftCollapsedWidth: 60
    });
  };

  /**
   * 完全关闭左边栏，即宽为0
   */
  onCollapseLeftSideAll = _ => {
    this.setState({
      collapsedLeftSide: true,
      leftCollapsedWidth: 0
    });
  };

  /**
   * 展开面包屑所在条中的多功能区
   */
  onExpandTopBar = _ => {
    this.setState({
      expandTopBar: true
    });
  };

  /**
   * 与上面相反
   */
  onCollapseTopBar = _ => {
    this.setState({
      expandTopBar: false
    });
  };

  /**
   * 切换左边栏中头部的开合
   */
  toggleSidebarHeader = _ => {
    this.setState({
      showSidebarHeader: !this.state.showSidebarHeader
    });
  };


  onChangeTheme = theme => {
    $$.setStore('theme', theme);
    this.setState({
      theme
    });
  };

  render() {
    const {
      collapsedLeftSide,
      leftCollapsedWidth,
      expandTopBar,
      showSidebarHeader,
      theme,
      user,
      currentMenu,
      isMobile
    } = this.state;
    const { routerData, location, global } = this.props;
    const { roleName,language } = global;
    const { menu, flatMenu } = global;
    const { childRoutes } = routerData;
    const classnames = cx('basic-layout', 'fixed-header',(language==="fr-FR"?"frlayout":""), {
      fixed: theme.layout && theme.layout.indexOf('fixedSidebar') !== -1,
      'fixed-breadcrumbs':
        theme.layout && theme.layout.indexOf('fixedBreadcrumbs') !== -1,
      'hided-breadcrumbs':
        theme.layout && theme.layout.indexOf('hidedBreadcrumbs') !== -1
    });

    return (
      <Layout className={classnames}>
        <Header>
          <NavBar
            collapsed={collapsedLeftSide}
            onCollapseLeftSide={this.onCollapseLeftSide}
            onExpandTopBar={this.onExpandTopBar}
            toggleSidebarHeader={this.toggleSidebarHeader}
            theme={theme.navbar}
            user={user}
            isMobile={isMobile}
            roleType={roleName}
          />
        </Header>
        <Layout>
          <LeftSideBar
            collapsed={collapsedLeftSide}
            leftCollapsedWidth={leftCollapsedWidth}
            showHeader={showSidebarHeader}
            onCollapse={this.onCollapseLeftSide}
            onCollapseAll={this.onCollapseLeftSideAll}
            location={location}
            theme={theme.leftSide}
            flatMenu={flatMenu}
            currentMenu={currentMenu}
            menu={menu}
            user={user}
            isMobile={isMobile}
            language={language}
          />
          <Content>
            <Layout className="full-layout">
              <Content style={{ overflow: 'hidden' }}>
                <SwitchTransition>
                  <CSSTransition
                    key={location.pathname}
                    //classNames="fade"
                    timeout={200}
                  >
                    <Layout className="full-layout">
                      <Content className="router-page">
                        <Switch location={location} isMobile={isMobile}>{childRoutes}</Switch>
                      </Content>
                    </Layout>
                  </CSSTransition>
                </SwitchTransition>
              </Content>
            </Layout>
          </Content>

        </Layout>

      </Layout>
    );
  }
}

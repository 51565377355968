import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = (app) => ({
  key: 'workorder',
  path: '/orders/view-work-order-details',
  title: 'View Work Order Details -Order # - <<##>> - Client ID <<###>> - Billing ID <<##>>',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  props: true
});

export default (app) => createRoute(app, routesConfig);
import { dynamicWrapper, createRoute } from '@/utils/core';
import CallInLog from './logs/callinlog';
import CallOutLog from './logs/calloutlog';
import Operationloglog from './logs/operationlog';
import Accesslog from './logs/accesslog';
const routesConfig = (app) => ({
  key: 'systems.logs',
  path: '/systems/logs',
  title: 'Logs',
  indexRoute: '/systems/logs/callinlog',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  childRoutes: [
    CallInLog(app),
    CallOutLog(app),
    Operationloglog(app),
    Accesslog(app),
  ]
});

export default (app) => createRoute(app, routesConfig);

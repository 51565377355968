import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
    key:'systems.users',
    path: '/systems/users',
    title: 'Users',
    component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'systems.notificationtype',
  path: '/systems/configure/noteCategory',
  title: 'Note Category',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

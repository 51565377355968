import React from 'react';
import { connect } from 'dva';
import { Layout, Select } from 'antd';
import { router } from 'dva';
import logoImg from 'assets/images/logo.svg';
import { version } from '../../package.json';
import './styles/user.less';
import $$ from '@/cmn-utils';
import { languages } from '@/utils/constants';
import { FormattedMessage, injectIntl } from 'react-intl';
const { Switch, Link } = router;
const { Content, Header, Footer } = Layout;
let fresh;
@connect(({ global }) => ({ global }))
export default class UserLayout extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  changeLanguage = async (language) => {
    try {
      this.props.dispatch({
        type: 'global/setLanguage',
        payload: { language }
      });
    } catch (error) { }
  };
  componentDidMount() {

    fresh = setInterval(() => {

      try {
        $$.get("/auth/isoffline").then(async (res) => {

          if (res && res.status && res.data) {
            window.location.href = '/offline.html'
          }
        }).catch(err => {

        });
      } catch (error) {

      }
    }, 10000);
  }
  componentWillUnmount() {

    clearInterval(fresh);
  }
  render() {
    const { routerData } = this.props;
    const { childRoutes } = routerData;
    const { global } = this.props;
    const { language } = global;

    return (
      <Layout className="full-layout user-layout fixed">
        <Header className="navbar">

          <ul className="nav">
            <li className="mobile-logo">
              <img src={logoImg} alt="logo" className="logo" />
            </li>
            <li className="language">
              {/* <Select
                className="language-select"
                value={language}
                name="language"
                onChange={this.changeLanguage}
                dropdownClassName="language-select-dropdown"
                showArrow={true}
              >
                {languages.map((l) => (
                  <Option key={l.value} value={l.value}>
                    <i className={l.icon}></i>
                    &nbsp; {l.text}
                  </Option>
                ))}
              </Select> */}
            </li>
          </ul>

        </Header>
        <Content>
          <Switch>{childRoutes}</Switch>
        </Content>
        <Footer>
          <div className="foot_content">
            <p>              
              By accessing the TPIA Portal you accept and agree to be bound to the
              <a
                href="https://www.cogeco.ca/sites/default/files/pdf/Legal/digital_content_license_agreement_hosting_services_en_o.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                {' '} End-User License Agreement Terms and Conditions

              </a>
            </p>
            <p>
            <a
                href="https://www.cogeco.ca/sites/default/files/pdf/Legal/convention_de_licence_relative_au_contenu_numerique_hebergement_fr_q.pdf"
                target="_blank"
                rel="noopener noreferrer"
              > En accédant au portail TPIA vous acceptez les conditions de Modalités des accords de licence pour utilisateurs</a>
            </p>
            <p>
              © {new Date().getFullYear()}
              {' '}
              <FormattedMessage
                id="userlayout.foot.three"
                defaultMessage="Cogeco Connexion™"
              />
              {' '}|{' '}
              <a
                href="https://www.cogeco.ca/en/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy</a> / <a
                href="https://www.cogeco.ca/fr/vie-privee"
                target="_blank"
                rel="noopener noreferrer"
              >Politique de confidentialité
               
              </a>

              {' '}|{' '}
              <a target="_blank" href="https://www.cogeco.ca/sites/default/files/pdf/Legal/terms-and-conditions_on-website.pdf">
                Terms of Use</a> / <a target="_blank" href="https://www.cogeco.ca/sites/default/files/pdf/Legal/modalites-generales_site-qc.pdf">Conditions d'utilisation  
              </a>
            </p>
            <p style={{ fontSize: '8px' }}>
              <FormattedMessage
                id="userlayout.foot.Version"
                defaultMessage="Version: v"
              />

              {version}</p>
          </div>
        </Footer>
      </Layout>
    );
  }
}
import React, { Component } from 'react';
import { Breadcrumb, Row, Col } from 'antd';
import { connect, router, routerRedux } from 'dva';
import Icon from '../Icon';
import cx from 'classnames';
import CSSAnimate from '../CSSAnimate';
import Mask from '../Mask';
import isEqual from 'react-fast-compare';
import './style/index.less';
import { getCurrentUserName, getCurrentUser } from '@/utils';
const { Link } = router;
@connect(({ global }) => ({ global }))
export default class TopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentRoute: TopBar.getRouteLevel(props.location.pathname) || []
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!isEqual(nextProps.currentRoute, prevState.currentRoute)) {
            return {
                currentRoute: TopBar.getRouteLevel(nextProps.location.pathname)
            };
        }

        return null;
    }

    static getRouteLevel = pathName => {

        const orderPaths = [];
        pathName.split('/').reduce((prev, next) => {
            const path = [prev, next].join('/');
            orderPaths.push(path);
            return path;
        });

        return orderPaths
            .map(item => window.dva_router_pathMap[item])
            .filter(item => !!item);
    };

    render() {
        const {
            children,
            items,
            global
        } = this.props;

        const { currentRoute } = this.state;
        const classnames = cx('topbar');

        return (
            <div className={classnames}>
                <header className="topbar-content">
                    {(currentRoute.length && (!items || !items.length)) ? (
                        <Breadcrumb className='fl'>

                            {/* <Breadcrumb.Item className="icon">
                <Icon type="home" />
              </Breadcrumb.Item> */}
                            <Breadcrumb.Item>
                            </Breadcrumb.Item>
                            {currentRoute.map((item, index) => (
                                <Breadcrumb.Item key={index} className=''>
                                    {index === currentRoute.length - 1 ? (
                                        <CSSAnimate className="inline-block" type="flipInX">
                                            {item.title}
                                        </CSSAnimate>
                                    ) : (
                                        <Link to={item.path}>{item.title}</Link>
                                    )}
                                </Breadcrumb.Item>
                            ))}
                        </Breadcrumb>
                    ) : null}
                    <Breadcrumb className='fl'>
                        {items && items.length && items
                            .filter((i) => !i.hide)
                            .map((p, i) => (
                                <Breadcrumb.Item key={p.label || i}>
                                    {p.path ? (
                                        <Link replace to={p.path} onClick={p.onClick}>
                                            {p.label}
                                        </Link>
                                    ) : (
                                        <span
                                            className={p.onClick ? 'pointer' : ''}
                                            onClick={p.onClick}
                                        >
                                            {p.label}
                                        </span>
                                    )}
                                </Breadcrumb.Item>
                            ))}
                    </Breadcrumb>
                    {children}
                    {global && global.roleName === "TPIA" && getCurrentUser().csg && getCurrentUser().ispName ? <span className='topbar_right f_r'>{getCurrentUser().csg || ''}-{getCurrentUser().ispName || ''}&nbsp;&nbsp;&nbsp;</span> : ''}
                </header>

            </div>
        );
    }
}


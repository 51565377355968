import { dynamicWrapper, createRoute } from '@/utils/core';
//import OrderTypeDeail from './ViewOrderTypeDetails';
const routesConfig = app => ({
  key:'management.customerstatus',
  path: '/management/customerstatus',
  title: 'View Customer Status',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  exact: true,
//   childRoutes: [
//     OrderTypeDeail(app),
//   ]
});

export default app => createRoute(app, routesConfig);

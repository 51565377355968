import React from 'react';
import ReactDOM from 'react-dom';
import dva, { dynamic, router, routerRedux } from 'dva';
import { IntlProvider } from 'react-intl';
import createLoading from 'dva-loading';
import { createBrowserHistory } from 'history';
import request from '@/cmn-utils/request';
import createRoutes from '@/routes';
import 'assets/styles/index.less';
import config from './config';
import { ConfigProvider } from 'antd';
import antd_enUS from 'antd/es/locale/en_US';
import antd_frFR from 'antd/es/locale/fr_FR';
import 'moment/locale/en-gb';
import localENUS from '@/locales/en-US';
import localFRFR from '@/locales/fr-FR';
import { languageCode } from '@/utils/constants';
import { homepage } from '../package.json';
import $$ from '@/cmn-utils';
import * as serviceWorker from './serviceWorker';
//import firebase from 'firebase/app';
//import 'firebase/auth';
import Provider from './provider'
const { ConnectedRouter } = routerRedux;
const { Router } = router;

// -> 初始化
const app = dva({
  history: createBrowserHistory({
    basename: homepage.startsWith('/') ? homepage : ''
  })
});
//if (config.loginType === 'firebase') {
// firebase.initializeApp(config.firebaseConfig);
// firebase.auth().onAuthStateChanged((user) => {
//   if (user) {
//     user.getIdTokenResult().then(res => { $$.setStore('token', res); });
//   } else {
//     $$.removeStore('token');
//   }
// });
//}
// -> 插件
app.use(createLoading());
app.use({ onError: config.exception.global });

// -> 请求
request.config(config.request);

// 使用mock数据
require('./__mocks__');
// -> Developer mock data
// if (process.env.NODE_ENV === 'development') {
//   require('./__mocks__');
// }

// -> loading
dynamic.setDefaultLoadingComponent(() => config.router.loading);

// -> 注册全局模型
app.model(require('./models/global').default);

// -> 初始化路由

app.router(({ history, app }) => {
  try {
    $$.get("/auth/isoffline").then(res => {
      if (res && res.status && res.data) {
        window.location.href = '/offline.html'
      }
    }).catch(err => {
      if (err) {
        // window.location.href = '/offline.html'
        console.log('err', err)
      }
    });
  } catch (error) {

  }
  return (

    <Provider>
      <Router history={history}>{createRoutes(app)}</Router>
    </Provider>

  )
});

// -> Start
app.start('#root');
// let language = config.language;
// const global = app._store.getState().global;
// if (global && global.language) {
//   language = global.language;
// }
// let antdLanguage = antd_enUS;
// let messages = localENUS;
// switch (language) {
//   case languageCode.FR:
//     antdLanguage = antd_frFR;
//     messages = localFRFR;
//     break;
//   case languageCode.EN:
//   default:
//     break;

// }
// ReactDOM.render(
//   <IntlProvider locale={language} messages={messages}>
//     <ConfigProvider locale={antdLanguage}>
//       <App />
//     </ConfigProvider>
//   </IntlProvider>, document.getElementById('root'));
// export global
export default {
  app,
  store: app._store,
  dispatch: app._store.dispatch
};

// 如果想可以离线使用，请使用register()代替unregister()。可能会带来一些问题，如缓存等
// 相关资料，可以从 https://bit.ly/CRA-PWA 了解
serviceWorker.unregister();

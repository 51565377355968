import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'management.Communication',
  path: '/management/communicationdetails',
  title: 'Communication Log Details',
  parentPath:'/management',
  selected:'/management/communication',
  component: dynamicWrapper(app,[import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);
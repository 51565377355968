import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = (app) => ({
  key:'customersadd',
  path: '/customers/add',
  title: 'Add New Customer',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  props: true
});

export default (app) => createRoute(app, routesConfig);
const codeMessage = {
  400: 'There is an error in the request. The server did not create or modify the data',
  401: 'The user is authorized, but access is forbidden',
  403: 'The request is for a non-existent record, the server has not operated',
  404: 'The request is for a non-existent record, the server has not operated',
  405: 'Disable the method specified in the request (method disabled)',
  406: 'The format of the request is not available',
  410: 'The requested resource has been permanently deleted and will no longer be available',
  422: 'A validation error occurred while creating an object',
  500: 'Server error, please check server',
  502: 'Gateway error',
  503: 'The service is unavailable, the server is temporarily overloaded or maintained',
  504: 'Gateway timeout',
  0: 'Request error',
  invalidURL: 'Invalid request URL',
  requestCanceled: 'The request was cancelled in advance',
  timeout: 'Timeout',
};

export default class RequestError extends Error {
  name = 'RequestError';

  constructor(message, code = 0) {
    super(message);

    this.text = codeMessage[code];
    this.code = code;
    this.date = new Date();
  }
}
import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'management.OrderTypes',
  path: '/management/ordertypedetails',
  title: 'Order Type Details',
  exact: true,
  parentPath:'/management',
  selected:'/management/ordertype',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

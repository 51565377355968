import React from 'react';
import PageLoading from 'components/Loading/PageLoading';
import { normal } from 'components/Notification';
import store from '@/cmn-utils/store';
import $$ from '@/cmn-utils';
import cookie from 'react-cookies';
import { EditMode, loginType, languageCode, AppConstants } from '@/utils/constants';

// 系统通知, 定义使用什么风格的通知，normal或antdNotice
const notice = normal;
const shopid = 1;
const editModel = EditMode.RowClick;//DoubleClick ,ClickIcon 
/**
 * 应用配置 如请求格式，反回格式，异常处理方式，分页格式等
 */
export default {
    /**
     * HTML的title模板
     */
    htmlTitle: 'Cogeco - {title}',
    /**
       * 编辑模式
       */
    editModel,
    /**
     * 登录方式 firebase,db
     */
    loginType: loginType.db,

    language: languageCode.EN,
    /**
     * 系统通知
     */
    notice,

    // 异步请求配置
    request: {
        prefix: '/api',

        // 每次请求头部都会带着这些参数
        withHeaders: () => {
            let user = store.getStore(AppConstants.USER);
            //console.log(window.location.href)
            //console.log({user})
            if (!user || !user.username) {
                try {
                    user = (cookie.load(AppConstants.USER));

                    //console.log({user1:user})
                } catch (error) {
                    console.log({ error })
                }

            }
            // else {
            //     user = (cookie.load(AppConstants.USER));
            //     console.log(user);
            // }
            //$$.delay(100);
            return ({
                Authorization: 'Bearer ' + (user && user.token),
                shopid: shopid,

            })
        },

        /**
         * 因为modelEnhance需要知道服务器反回的数据，
         * 什么样的是成功，什么样的是失败，如
         * {status: true, data: ...} // 代表成功
         * {status: false, message: ...} // 代表失败
         * 实际中应该通过服务端反回的response中的
         * 成功失败标识来进行区分
         */
        afterResponse: response => {
            // console.log(response)
            const { status, message, code } = response;
            if (status) {
                return response;
            }
            else {
                if (code === 401) {
                    if (window.location && window.location.pathname && window.location.pathname.toLocaleLowerCase() === '/sign/login') {
                        //throw new Error(message);
                    } else {
                        window.location.href = '/sign/login';
                    }

                    // 
                }
                else if (code === 400 && message === 'No permission') {
                    window.location.href = '/systems/customer';
                }

                else {
                    throw new Error(message);
                }
            }
        },
        errorHandle: err => {
            //     // 请求错误全局拦截
            if (err.name === 'RequestError') {
                notice.error(err.text || err.message);
            }
        }
    },

    // 全局异常
    exception: {
        global: (err, dispatch) => {

            const errName = err.name;
            // RequestError为拦截请求异常
            if (errName === 'RequestError') {
                notice.error(err.message);
                console.error(err);
            } else {
                console.error(err);
            }
        },
    },

    // 分页助手
    pageHelper: {
        // 格式化要发送到后端的数据
        requestFormat: pageInfo => {
            const { pageNum, pageSize, filters, sorts } = pageInfo;
            return {
                currentPage: pageNum,
                showCount: pageSize,
                sortMap: sorts,
                paramMap: filters
            };
        },

        // 格式化从后端反回的数据
        responseFormat: resp => {
            const {
                currentPage,
                showCount,
                totalResult,
                dataList,
                totalPage
            } = resp.data;
            return {
                pageNum: currentPage,
                pageSize: showCount,
                total: totalResult,
                totalPages: totalPage,
                list: dataList
            };
        }
    },

    // 路由加载效果
    router: {
        loading: <PageLoading loading />
    },
    appConfig: {
        //timeout: Number.parseInt(process.env.REACT_APP_TIME_OUT || '15'),
        portalUrl: process.env.REACT_APP_PORTAL_URL || ''
    },
    envtype: process.env.REACT_APP_ENVTYPE
    ,
    oauth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authorization_URL: process.env.REACT_APP_AUTHORIZATION_URL,
        redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    },
    //  firebase
    firebaseConfig: {
        apiKey: process.env.REACT_APP_APIKEY,
        authDomain: process.env.REACT_APP_AUTHDOMAIN,
        databaseURL: process.env.REACT_APP_DATABASEURL,
        projectId: process.env.REACT_APP_PROJECTID,
        storageBucket: process.env.REACT_APP_STORAGEBUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
        appId: process.env.REACT_APP_APPID,
        measurementId: process.env.REACT_APP_MEASUREMENTID
    },
    /**
     * 模拟数据时包装反回数据
     * 因为，后端反回数据时一般都会在外边包装一层状态信息
     * 如成功时：
     * {
     *   status: true,
     *   data: responseData
     * }
     * 或出错时：
     * {
     *   status: false,
     *   code: 500,
     *   message: '用户名或密码错误'
     * }
     * 这里就是配置这两个函数，为了我们模拟数据时可以少写几行代码的 orz...
     */
    mock: {
        toSuccess: response => ({
            status: true,
            data: response
        }),

        toError: message => ({
            status: false,
            message: message
        })
    }
};



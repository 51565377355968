import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = (app) => ({
  key: 'workorder',
  path: '/work-order-details',
  title: 'View Work Order Details',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  props: true
});

export default (app) => createRoute(app, routesConfig);
import { dynamicWrapper, createRoute } from '@/utils/core';
// import billingServices from './configure/billingServices';
import noteCategory from './configure/noteCategory';
import Package from './configure/package';

const routesConfig = (app) => ({
  key: 'systems.notificationtype',
  path: '/systems/configure',
    title: 'Note Category',
    indexRoute: '/systems/configure/noteCategory',
    component: dynamicWrapper(app, [import('./model')], () => import('./components')),
    childRoutes: [
       // billingServices(app),
        noteCategory(app),
        Package(app),
    ]
});

export default (app) => createRoute(app, routesConfig);
import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'customersadd',
  path: '/management/cmts_region/list',
    title: 'View CMTS Region',
    component: dynamicWrapper(app, [import('./model')], () => import('./components')),

});

export default app => createRoute(app, routesConfig);
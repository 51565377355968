import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key: 'management.certifiedmodems',
  path: '/management/certified-modems-details',
  title: 'Certified Modems Details',
  parentPath: '/management',
  selected: '/management/certified-modems',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);
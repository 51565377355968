import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = (app) => ({
  key: 'reports.status',
  path: '/reports/customer-status',
  title: 'Customer Status',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
 // props: true
});

export default (app) => createRoute(app, routesConfig);
import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = (app) => ({
  key: 'filedchecklist',
  path: '/field-checks-list',
    title: 'View Field Checks',
    component: dynamicWrapper(app, [import('./model')], () => import('./components')),
    // props: true
});

export default (app) => createRoute(app, routesConfig);
import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'systems.email.EmailHistory',
  path: '/systems/email/history',
  title: 'Email History',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

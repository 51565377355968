import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'nopermission',
  path: '/profile/changepassword',
  title: 'Change Password',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

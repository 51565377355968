import { dynamicWrapper, createRoute } from '@/utils/core';
import details from './cmtsregionDetail';
import list from './cmtsregion_list';
const routesConfig = (app) => ({
  key: 'management.Communication',
  path: '/management/cmts_region',
  title: 'CMTS Region',
  indexRoute: "/management/cmts_region/list",
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  childRoutes: [list(app), details(app)],
});

export default app => createRoute(app, routesConfig);
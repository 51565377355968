import $$ from "@/cmn-utils";
import modelEnhance from "@/utils/modelEnhance";
import { AppConstants } from '@/utils/constants';
import moment from 'moment';
import cookie from 'react-cookies';
// const language = $$.getStore(AppConstants.LANGUAGE, cookie.load(AppConstants.language));

const language = cookie.load(AppConstants.LANGUAGE);
export default modelEnhance({
  namespace: "global",

  state: {
    shopid: 1,
    menu: [],
    flatMenu: [],
    isMobile: false,
    language: language || "en-US",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    dateFormat: "YYYY-MM-DD",
    timeFormat: "HH",
    logoimg: "",
    shopname: "",
    roleName: '',
    roleType: '',
    twofa: false,
    needCW: false,
    permissions: [],
    languageTexts: {},
  },
  subscriptions: {
    setup({ dispatch, history }) {
      dispatch({
        type: 'init'
      });

    }
  },
  effects: {
    *init({ payload }, { call, put, select }) {
      yield put({
        type: 'getlanguages',
      });

    },
    *getCurrent({ payload }, { call, put }) {
      const { status, data } = yield call(getAccountCurrent, payload);
      if (status) {
        const loopMenu = (menu, pitem = {}) => {
          menu.forEach((item) => {
            if (pitem.path) {
              item.parentPath = pitem.parentPath
                ? pitem.parentPath.concat(pitem.path)
                : [pitem.path];
            }
            if (item.children && item.children.length) {
              loopMenu(item.children, item);
            }
          });
        };
        loopMenu(data.menus);

        yield put({
          type: "getCurentSuccess",
          payload: data,
        });
      }
    },
    *setIsMobile({ payload }, { put }) {
      const { isMobile } = payload;
      yield put({
        type: "getIsMobileSuccess",
        payload: isMobile,
      });
    },
    *setLanguage({ payload }, { put }) {

      const { language } = payload;
      $$.setStore(AppConstants.LANGUAGE, language);
      //(AppConstants.LANGUAGE, language)
      yield put({
        type: "setLanguageCode",
        payload: language,
      });
    },
    *getlanguages({ payload }, { put, call, }) {
      const { status, data } = yield call(getAllLanguages, payload);
      if (status) {
        yield put({
          type: "setLanguages",
          payload: data,
        });
      }
    },
    *changeTimeZoneInfo({ payload }, { put, take }) {
      yield put({
        type: "@request",
        payload: {
          notice: true,
          url: "/users/saveTimezone",
          data: payload.values,
        },
      });
      yield take("@request/@@end");
      yield put({
        type: "getCurrent",
      });
    },
    *loginOut({ payload }, { put, take, select }) {

      let user = $$.store.getStore(AppConstants.USER);
      if (!user || !user.username) {
        try {
          user = (cookie.load(AppConstants.USER));
        } catch (error) {

        }

      }
      if (user && user.token) {
        yield put({
          type: "@request",
          payload: {
            notice: false,
            url: "/auth/logout",
            //data: payload.values,
          },
        });
        yield take("@request/@@end");
        yield put({
          type: "setLoginOut",

        });
      }
      yield put({
        type: "setLoginOut",

      });

    },
    *refreshToken({ payload }, { put, take }) {
      yield put({
        type: "@request",
        afterResponse: resp => {
          // $$.setStore(AppConstants.USER, resp.data);

          cookie.save(AppConstants.USER, JSON.stringify(resp.data),
              {path: '/', secure: true, sameSite: 'strict'})
          // $$.setStore(AppConstants.REFRESH_TOKEN_TIME, moment().valueOf());

          cookie.save(AppConstants.REFRESH_TOKEN_TIME, moment().valueOf(),
              {path: '/', secure: true, sameSite: 'strict'})
        },
        payload: {
          notice: false,
          url: "/auth/refresh",
        },
      });
      yield take("@request/@@end");

    },
  },

  reducers: {
    getCurentSuccess(state, { payload }) {
      if (payload && payload.language) {
        const { language } = payload;
        // $$.setStore(AppConstants.LANGUAGE, language);
        cookie.save(AppConstants.LANGUAGE, language,
            {path: '/', secure: true, sameSite: 'strict'})
      }
      return {
        ...state,
        menu: payload.menus,
        flatMenu: getFlatMenu(payload.menus),
        timeZone: payload.timezone
          ? payload.timezone
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateFormat: payload.dateFormat ? payload.dateFormat : "YYYY-MM-DD",
        timeFormat: payload.timeFormat ? payload.timeFormat : "HH",
        logoimg: payload.logo,
        shopname: payload.shopsName,
        roleName: payload.roleName,
        roleType: payload.roleType,
        ispName: payload.ispName,
        ispnumber: payload.ispnumber,
        province: payload.province,
        language: payload.language,
        permissions: payload.permissions,
        timeout: payload.timeout,
        twofa: payload.twofa,
        needCW: payload.needCW,
      };
    },
    getIsMobileSuccess(state, { payload }) {
      return {
        ...state,
        isMobile: payload,
      };
    },
    setLanguages(state, { payload }) {

      return {
        ...state,
        languageTexts: payload,
      };
    },
    setLanguageCode(state, { payload }) {
      return {
        ...state,
        language: payload,
      };
    },
    setLoginOut(state, { payload }) {
      $$.removeStore(AppConstants.USER);
      $$.removeStore(AppConstants.LAST_REQUERT_TIME);
      $$.removeStore(AppConstants.REFRESH_TOKEN_TIME);
      cookie.remove(AppConstants.USER, {path: '/'});
      cookie.remove(AppConstants.LAST_REQUERT_TIME, {path: '/'});
      cookie.remove(AppConstants.REFRESH_TOKEN_TIME, {path: '/'});
      if (window.location && window.location.pathname && window.location.pathname.toLocaleLowerCase() !== '/sign/login') {
        window.location.href = '/sign/login';
      }
      return {
        ...state,
        menu: [],
        flatMenu: [],
        //language: "en-US",
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        dateFormat: "YYYY-MM-DD",
        timeFormat: "HH",
        logoimg: "",
        shopname: "",
        permissions: [],
        ispnumber: '',
        ispName: '',
        province: '',
      };
    },
  },
});

export function getFlatMenu(menus) {
  let menu = [];
  menus.forEach((item) => {
    if (item.children) {
      menu = menu.concat(getFlatMenu(item.children));
    }
    menu.push(item);
  });
  return menu;
}

export async function getMenu(payload) {
  return $$.post("/auth/menu", payload);
}

export async function getAccountCurrent() {
  return $$.get("/account/current");
}
export async function getAllLanguages() {
  return $$.get("/static/getlanguagebycode");
}
export async function getLogo() {
  return $$.get("/account/current");
}

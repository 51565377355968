import { dynamicWrapper, createRoute } from '@/utils/core';
import   ChangePassword  from './profile/change_password';
import  ChangeProfile  from "./profile/profile";
const routesConfig = app => ({
  key:'nopermission',
  path: '/profile',
  title: 'Profile',
  indexRoute: '/profile/changeprofile',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  childRoutes: [
    ChangePassword(app),
    ChangeProfile(app)
  ]
});

export default app => createRoute(app, routesConfig);

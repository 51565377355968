import { ConfigProvider } from 'antd';
import { connect } from 'dva';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import config from './config';
import antd_enUS from 'antd/es/locale/en_US';
import antd_frFR from 'antd/es/locale/fr_FR';
import 'moment/locale/en-gb';
import localENUS from '@/locales/en-US';
import localFRFR from '@/locales/fr-FR';
import { languageCode } from '@/utils/constants';
import $$ from '@/cmn-utils';

const Provider = ({ app, children }) => {

    let language = config.language;
    const { global } = app;
    const { languageTexts } = global || {};
    if (global && global.language) {
        language = global.language;
    }
    let antdLanguage = antd_enUS;
    let messages = localENUS;
    switch (language) {
        case languageCode.FR:
            antdLanguage = antd_frFR;
            messages = (languageTexts && languageTexts[language]) ? languageTexts[language] : localFRFR;
            break;
        case languageCode.EN:
            messages = (languageTexts && languageTexts[language]) ? languageTexts[language] : localENUS;
        default:
            break;

    }


    return (
        <IntlProvider locale={language} messages={messages}>
            <ConfigProvider locale={antdLanguage}>
                {children}
            </ConfigProvider>
        </IntlProvider>
    );
};

export default (connect((app) => ({
    app,
}))(Provider));
import { dynamicWrapper, createRoute } from '@/utils/core';
import OrderTypeDeail from './ViewOrderTypeDetails';
const routesConfig = app => ({
  key:'management.OrderTypes',
  path: '/management/ordertype',
  title: 'View Order Types',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  exact: true,
  childRoutes: [
    OrderTypeDeail(app),
  ]
});

export default app => createRoute(app, routesConfig);

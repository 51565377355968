import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'nopermission',
  path: '/sign/reset_password',
  title: 'Reset Password',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { createRoutes } from '@/utils/core';
import BasicLayout from '@/layouts/BasicLayout';
import UserLayout from '@/layouts/UserLayout';
import NoneLayout from '@/layouts/NoneLayout';
import NotFound from './Pages/404';
import TermOfUse from './Pages/terms-of-use';
import PrivacyPolicy from './Pages/privacy-policy';
import Welcome from './Account/welcome';
import Login from './Account/Login';
import Oauth from './Account/Oauth';
import Register from './Account/Register';
import ForgetPassword from './Account/forgot_password';
import ResetPassword from './Account/reset_password';
import ChangePassword from './Account/change_password';
import UploadCsv from './Pages/UploadCsv';
//import FiledCheckList from './Fieldcheck/filedchecklist';
import OpenWorkOrder from './OpenWorkOrder';
import EC from './EC';
import WorkOrderDetails from './OpenWorkOrder/WorkOrderDetails';
import SearchCustomers from './SearchCustomers';
import ResubmitOrder from './Systems/ResubmitOrder';


import Users from './Systems/users';
import Roles from './Systems/roles';
import RolePermission from './Systems/roles_permission';
import Image from './Systems/image';
import Content from "./Systems/Content";
import Timezone from "./Systems/timezone";

//import Task from "./Systems/schedule_tasks";
import Language from "./Systems/language";
import Logs from "./Systems/logs";
import Email from "./Systems/email";
import Profile from './Profile';
import Setting from "./Systems/setting";
import Notification from "./Systems/Notification/notification/notification";
import Customer from "./Systems/Customer";
import Work from "./Orders/view_work";
import workdetali from "./Orders/view_work_detali";
import addcustomer from "./Systems/Add_Customer";
import customerdetail from "./Systems/Customer/CustomerDetail";
import customermodify from "./Systems/Customer/CustomerModify";
import Fieldchecklists from "./Fieldchecklists";
import viewworktable from "./Orders/view_order_work";
import Configure from "./Systems/Configure";
import ISP from "./Systems/ISP";
import Tpiadetails from "./Systems/TpiaDetails";
import ViewOrderType from "./Systems/ViewOrderType";
import ViewOrderTypeDetails from "./Systems/ViewOrderType/ViewOrderTypeDetails";
import PortalNotices from "./Systems/PortalNotices";
import Products from "./Systems/Products";
import ProductDetails from "./Systems/Products/ProductDetails";
import CertifiedModems from "./Systems/CertifiedModems";
import CertifiedModemsDetails from "./Systems/CertifiedModems/CertifiedModemsDetails";
import LogCategories from "./Systems/LogCategories";
import LogCategoriesDetalis from "./Systems/LogCategories/LogCategoriesDetalis";
import PortalUsers from "./Systems/PortalUsers";
import PortalUsersDetails from "./Systems/PortalUsers/PortalUsersDetails";
import Portal from "./Systems/Portal";
import WorkOrderStatus from "./Systems/WorkOrderStatus";
import CMTSRegion from "./Systems/CMTSRegion";
import NotificationType from "./Systems/NotificationType";
import NotificationTypeEmail from "./Systems/NotificationTypeEmail";
import CustomersStatus from "./Systems/CustomersStatus";
import tpiaService from "./TPIACustomerService";
import CityCustomer from "./Reports/City";
import StatusCustomer from "./Reports/Status";
import workorders from "./Reports/workorders";
import OrderError from "./Reports/OrderError";
import customerProduct from "./Reports/customersbyprod";
import OrderSummary from "./Reports/ordersummary";
import OrderSummaryDay from './Reports/ordersummarybyday';
import GridReport from './Reports/gridreport';
import Callback from './Callback';
import Loading from './Pages/loading';
import BulkImport from "@/routes/Systems/BulkImport";
import BulkImportReport from "@/routes/Reports/BulkImportReport";
import JobDetails from "@/routes/Reports/BulkImportReport/JobDetails";
import ViewJob from "@/routes/Reports/BulkImportReport/ViewJobs";
/**
 * 主路由配置
 * 
 * path 路由地址
 * component 组件
 * indexRoute 默认显示路由
 * childRoutes 所有子路由
 * NotFound 路由要放到最下面，当所有路由当没匹配到时会进入这个页面
 */
const routesConfig = app => [
    {
        path: '/sign',
        title: 'Sign',
        indexRoute: '/sign/login',
        component: UserLayout,
        childRoutes: [
            Welcome(app),
            Login(app),
            Oauth(app),
            Register(app),
            ForgetPassword(app),
            ResetPassword(app),
            ChangePassword(app),
            NotFound()
        ]
    },
    {
        path: '/callback',
        title: 'callback',
        //indexRoute: '/callback',
        component: UserLayout,

        childRoutes: [
            Callback(app),
            NotFound()
        ]
    },
    {
        path: '/loading',
        title: 'loading',
        //indexRoute: '/callback',
        component: NoneLayout,

        childRoutes: [
            Loading(app),
            NotFound()
        ]
    },

    {
        path: '/privacy-policy',
        title: 'Privacy Policy',
        indexRoute: '/privacy-policy',
        component: UserLayout,

        childRoutes: [
            PrivacyPolicy(app),
            NotFound()
        ]
    },
    {
        path: '/terms-of-use',
        title: 'Terms of Use',
        indexRoute: '/terms-of-use',
        component: UserLayout,
        exact: true,
        childRoutes: [
            TermOfUse(app),
            NotFound()
        ]
    },
    {
        path: '/',
        title: 'Notifications',
        component: BasicLayout,
        indexRoute: '/systems/notification',
        childRoutes: [
            // Dashboard(app),
            // Blank(app),
            // Product(app),
            // ProductModify(app),
            // Mastercatalog(app),
            // Order(app),
            // CRUD(app),
            // Affiliatecodes(app),
            // Brandings(app),
            // SEO(app),
            // Social(app),
            Users(app),
            Roles(app),
            RolePermission(app),
            //Task(app),
            Content(app),
            Timezone(app),
            //Carousel(app),
            //OrderDetails(app),
            Language(app),
            Logs(app),
            Email(app),
            Profile(app),
            Setting(app),
            Notification(app),
            Customer(app),
            Work(app),
            addcustomer(app),
            Fieldchecklists(app),
            workdetali(app),
            viewworktable(app),
            Image(),
            // FiledCheckList(app),
            OpenWorkOrder(app),
            customerdetail(app),
            customermodify(app),
            OrderError(app),
            Configure(app),
            EC(app),
            ISP(app),
            UploadCsv(app),
            WorkOrderDetails(app),
            Tpiadetails(app),
            ViewOrderType(app),
            ViewOrderTypeDetails(app),
            PortalNotices(app),
            Products(app),
            ProductDetails(app),
            CertifiedModems(app),
            CertifiedModemsDetails(app),
            LogCategories(app),
            LogCategoriesDetalis(app),
            PortalUsers(app),
            PortalUsersDetails(app),
            SearchCustomers(app),
            CMTSRegion(app),
            tpiaService(app),
            Portal(app),
            NotificationType(app),
            NotificationTypeEmail(app),
            CityCustomer(app),
            StatusCustomer(app),
            workorders(app),
            customerProduct(app),
            OrderSummary(app),
            OrderSummaryDay(app),
            WorkOrderStatus(app),
            CustomersStatus(app),
            GridReport(app),
            BulkImport(app),
            BulkImportReport(app),
            JobDetails(app),
            ViewJob(app),
            ResubmitOrder(app),
            NotFound()
        ]
    },


];

export default app => createRoutes(app, routesConfig);

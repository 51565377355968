import React from 'react';
import { router } from 'dva';
import { Layout, Row, Col } from 'antd';
import Icon from '../Icon';
import errorImg from './style/images/error403.svg';
const { Link } = router;
const { Content } = Layout;

export default () => (
  <Layout className="full-layout">
    <Content>



      <div id="banner">
        <div id="banner-content">
          <img src="https://tpia.connexops.ca/static/media/logo.ee7aeaf3.svg" alt="Cogeco" />
        </div>
      </div>

      <div class="page_title" id="main-content">
        The TPIA Portal is currently offline for maintenance. We’re really sorry to be holding you up today!<br />Our teams are working
        hard to get everything up and running as soon as possible. Please contact <a
          href="mailto:ispos@cogeco.com">ispos@cogeco.com</a> for any urgent matters.
      </div>
      <hr />
      <div class="page_title" id="main-content">
        Le portail est hors ligne pour maintenance. Nous sommes vraiment désolés de ne pas vous fournir le meilleur service que possible
        en ce moment.<br /> Nous faisons tout ce que nous pouvons pour régler le problème aussitôt que possible.
        En cas d’urgence veuillez contacter <a
          href="mailto:ispos@cogeco.com">ispos@cogeco.com</a>.
      </div>


      <div class="footer">
        <p>By accessing the TPIA Portal you accept and agree to be bound to the End-User License Agreement Terms and
          Conditions</p>
        <p>© 2021 Cogeco Connexion™ | Privacy Policy | Terms of Use</p>
        <br />
        <br />
      </div>

    </Content>
  </Layout>
);

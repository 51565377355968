import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'customersadd',
  path: '/systems/contents',
  title: 'content',
  component: dynamicWrapper(app, [], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';
const routesConfig = app => ({
  key:'systems.portal',
  path: '/systems/portal',
  title: 'Portal',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  exact: true,
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from "@/utils/core";
import History from "./email/email_history";
import Template from "./email/email_template";
import Server from "./email/email_server";
const routesConfig = (app) => ({
  key:'systems.email',
  path: "/systems/email",
  title: "Email",
  indexRoute: "/systems/email/history",
  component: dynamicWrapper(app, [import("./model")], () =>
    import("./components")
  ),
  childRoutes: [History(app), Template(app), Server(app)],
});

export default (app) => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'nopermission',
  path: '/terms-of-use',
  title: 'Terms of Use',
  exact:true,
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = (app) => ({
  key: 'openworkorders',
  path: '/open-work-orders',
  title: 'Search Work Orders',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
 // props: true
});

export default (app) => createRoute(app, routesConfig);
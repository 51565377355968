import { dynamicWrapper, createRoute } from '@/utils/core';
import RolePermission from '../roles_permission'
const routesConfig = app => ({
  key:'systems.roles',
  path: '/systems/roles',
  title: 'Roles',
  component: dynamicWrapper(app, [import('./model')], () => import('./components')),
  exact: true,
  childRoutes: [
    RolePermission(app),
  ]
});

export default app => createRoute(app, routesConfig);

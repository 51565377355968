import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key:'management.ISP',
  path: '/management/tpiadetails',
  title: 'TPIA Details',
  parentPath:'/management',
  selected:'/management/isp',
  component: dynamicWrapper(app,[import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);
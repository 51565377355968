import { dynamicWrapper, createRoute } from '@/utils/core';
import CertifiedModemsDetails from './CertifiedModemsDetails';

const routesConfig = app => ({
  key:'management.certifiedmodems',
  path: '/management/certified-modems',
  title: 'View Certified Modems',
  component: dynamicWrapper(app,[import('./model')], () => import('./components')),
  exact: true,
  childRoutes: [
    CertifiedModemsDetails(app),
  ]
});

export default app => createRoute(app, routesConfig);
import moment from 'moment';
import $$ from '@/cmn-utils';
import { AppConstants } from '@/utils/constants';
import cookie from 'react-cookies';
export const getExportFileName = (name) => {
    return `${name} ${moment().format('DDMMYYYYHHmmss')}`;
};

export const loginOut1 = () => {
    // this.props.dispatch({
    //     type: 'global/loginOut',

    //   });
    // $$.removeStore(AppConstants.USER);    
    // $$.removeStore(AppConstants.LAST_REQUERT_TIME);
    // $$.removeStore(AppConstants.REFRESH_TOKEN_TIME);
    // cookie.remove(AppConstants.USER);    
    // cookie.remove(AppConstants.LAST_REQUERT_TIME);
    // cookie.remove(AppConstants.REFRESH_TOKEN_TIME);

}
export const getCurrentUser = () => {
    let user = $$.getStore(AppConstants.USER);
    if (!user || !user.username) {
        try {
            user = (cookie.load(AppConstants.USER));
        } catch (error) {

        }

    }
    return user || {};
}
export const getCurrentUserName = () => {
    const user = getCurrentUser();
    if (user) {
        return user.realname || user.username.split('@')[0];
    }
    return '';
}
export const getIspName = () => {
    const user = getCurrentUser();
    if (user) {
        return user.ispName || '';
    }
    return '';
}
export const getencryptionid = (id) => {
    return new Promise(function (resolve, reject) {
        $$.get('/encryption/getid/' + id).then(res => {
            if (res.data) {
                resolve(res.data);
            }
            resolve(null);
        });
    });


}
export const setencryptionid = async (id) => {
    return new Promise(function (resolve, reject) {
        $$.post('/encryption/setid/' + id).then(res => {
            if (res.data) {
                resolve(res.data);
            }
            resolve(null);
        });
    });

}
import { dynamicWrapper, createRoute } from '@/utils/core';

const routesConfig = app => ({
  key: 'reports.bulkimportreport',
  path: '/reports/bulk-import-report',
  title: 'Bulk Import Report',
  component: dynamicWrapper(app, [import('./model')], () => import('./components'))
});

export default app => createRoute(app, routesConfig);

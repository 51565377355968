/**
 * 模拟请求数据
 * @param {FetchMock} fetchMock 当现有条件不满足时，可以使用fetchMock来进行扩展
 * @param {function} delay 增加延迟时间 ms 例: delay(mockData) 或 delay(mockData, 200)
 * @param {function} mock 使用mock生成数据，例:

   mock({
     'string|1-10': '★' // 生成最少1颗，最多10颗星字符
   })

   // {'string': '★★★★★★'} 

  更多用法参考 http://mockjs.com/examples.html
 */
export default ({ fetchMock, delay, mock, toSuccess, toError }) => {
  // 如果现有扩展不满足需求，可以直接使用fetchMock方法
  // fetchMock.mock(/httpbin.org\/post/, {/* response */}, {/* options */});

  return {
    "/api/auth/menu": (options) =>
      toSuccess(
        // [
        //   {
        //     name: "Dashboard",
        //     icon: "DashboardOutlined",
        //     path: "/dashboard",
        //   },
        //   {
        //     name: "Product Management",
        //     icon: "AppstoreOutlined",
        //     path: "/products",
        //     children: [
        //       {
        //         name: "Product Listing",
        //         path: "/products/manage",
        //         // children:[
        //         //   {
        //         //     name: 'Product Modify',
        //         //     path: '/products/modify',
        //         //   }
        //         // ]
        //       },
        //       {
        //         name: "Add Product",
        //         path: "/products/modify",
        //       },
        //       {
        //         name: "Master Catalog",
        //         path: "/products/mastercatalog",
        //       },
        //     ],
        //   },
        //   {
        //     name: "Order Management",
        //     icon: "DollarCircleFilled",
        //     path: "/orders",
        //     children: [
        //       {
        //         name: "Orders",
        //         path: "/orders/manage",
        //       },
        //     ],
        //   },
        //   {
        //     name: "Report",
        //     icon: "LineChartOutlined",
        //     path: "/reports",
        //     children: [
        //       {
        //         name: "Daily Transaction Summary",
        //         path: "/reports/dailytransactionsummary",
        //       },
        //     ],
        //   },
        //   {
        //     name: "Promotions/Discounts",
        //     icon: "GoldFilled",
        //     path: "/promotions",
        //     children: [
        //       {
        //         name: "Promotions",
        //         path: "/promotions/manage",
        //       },
        //       {
        //         name: "Affiliate Codes",
        //         path: "/promotions/affiliatecodes",
        //       },
        //       {
        //         name: "Bobus",
        //         path: "/promotions/bobus",
        //       },
        //     ],
        //   },
        //   {
        //     name: "System",
        //     icon: "SettingFilled",
        //     path: "/systems",
        //     children: [
        //       {
        //         name: "Site Branding",
        //         path: "/systems/brandings",
        //       },
        //       {
        //         name: "Manage Content",
        //         path: "/systems/contents",
        //       },
        //       // {
        //       //   name: "Manage Images",
        //       //   path: "/systems/images",
        //       // },
        //       // {
        //       //   name: "Manage Labels",
        //       //   path: "/systems/labels",
        //       // },
        //       {
        //         name: "Manage SEO",
        //         path: "/systems/seo/:page?",
        //       },
        //       {
        //         name: "Manage Social",
        //         path: "/systems/social",
        //       },
        //       {
        //         name: "System Users",
        //         path: "/systems/users",
        //       },
        //       {
        //         name: "System Roles",
        //         path: "/systems/roles/:roleid?",
        //       },
        //       {
        //         name: "Manage Operation Policies",
        //         path: "/systems/policies",
        //       },
             
        //        {
        //         name: "Manage Carousel",
        //         path: "/systems/carousel",
        //       },
        //       {
        //         name: "System Logs",
        //         path: "/systems/logs/:logstype?",
        //       },
        //       {
        //         name: "Schedule Task",
        //         path: "/systems/scheduletask",
        //       },
        //       {
        //         name: "Manage Language",
        //         path: "/systems/language",
        //       },
        //       {
        //         name: "Manage Email",
        //         path: "/systems/email/:page?",
        //       },
        //       {
        //         name: "System Setting",
        //         path: "/systems/setting/:setingtype?",
        //       },
        //     ],
        //   },
        // ],
        400
      ),
  };
};

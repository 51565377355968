import React, { Component } from 'react';
import { Button, Modal, Spin } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import './style/confirm-button.less';
export default class ConfirmButton extends Component {
    state = {
        visible: false,
        loading: false,
    };

    openConfirmModal = () => {
        if (this.props.condition && this.props.condition() === false) {
            return;
        }
        this.setState({ visible: true });
    };

    static getDerivedStateFromProps(props, state) {
        if (props.visible !== undefined && props.visible !== state.visible) {
            return {
                visible: props.visible,
            };
        }
        return null;
    }

    confirm = async () => {
        this.setState({
            loading: true,
        });
        try {
            const res = await this.props.onConfirm();
            if (res !== false) {
                this.setState({
                    visible: false,
                });
            }
        } catch (e) {
            console.log(e);
        }
        this.setState({
            loading: false,
        });
    };

    cancel = () => {
        this.setState({
            visible: false,
        });
        this.props.onCancel && this.props.onCancel();
    };

    render() {
        const { visible, loading } = this.state;
        const {
            text,
            title,
            children,
            btnType,
            isContainer,
            hide,
        } = this.props;

        return (
            <>
                {!hide && (
                    <Button
                        onClick={this.openConfirmModal}
                        type={btnType || 'primary'}
                        className={(isContainer ? 'confirm-container' : '')+" ant-btn-round cogeco_btn"}
                    >
                        {children || <FormattedMessage id="common.button.confirm" defaultMessage="Confirm" />}
                    </Button>
                )}

                <Modal
                    title={
                        title || title === null ? title : <FormattedMessage id="common.confirm_button.confirm_operation" defaultMessage="Confirm operation" />
                    }
                    className="confirm-button"
                    visible={visible}
                    maskClosable={false}
                    onCancel={this.cancel}
                    footer={null}
                    closable={!loading}
                >
                    <Spin spinning={loading}>
                        <p style={{ padding: '0 10px' }}>
                            {text || ''}
                        </p>
                        <div className="text-right">
                            <Button onClick={this.cancel}><FormattedMessage id="common.button.cancel" defaultMessage="Cancel" /></Button>
                            <Button type="primary" onClick={this.confirm} className="ant-btn-round cogeco_btn">
                                <FormattedMessage id="common.button.confirm" defaultMessage="Confirm" />
                            </Button>
                        </div>
                    </Spin>
                </Modal>
            </>
        );
    }
}
